import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import List from './List';


function Category ({ category, toggleArrow, selectedCategory, clearSearchTerm, searchTerm }) {
  const isDesktop = useMediaQuery({ query: '(min-width: 800px)' })
  const inputEl = useRef(null)
  const arrow = (bool) => {
    if(bool) {
      inputEl.current.focus()
      return <svg className="svg-arrow" role="img" viewBox="0 0 24 24" aria-labelledby="chevronUpIconTitle" strokeWidth="2" strokeLinecap="round" strokeLinejoin="miter" fill="none"> <title id="chevronUpIconTitle">Chevron Up</title> <polyline points="6 14 12 8 18 14 18 14"/></svg> 
    }
    else{
      return <svg className="svg-arrow" role="img" viewBox="0 0 24 24" aria-labelledby="chevronDownIconTitle" strokeWidth="2" strokeLinecap="round" strokeLinejoin="miter" fill="none"> <title id="chevronDownIconTitle">Chevron Down</title> <polyline points="6 10 12 16 18 10"/></svg>
    }
  }
  function scrollToView() {
    inputEl.current.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <div>
      {isDesktop ?
      <li id={`category_${category.id}`} 
          onClick={() => {
            selectedCategory(`category_${category.id}`)
            clearSearchTerm()
          }}>{category.name}</li>
      :
      <>
        {searchTerm.length > 2 ?
          <>
            <li>
              {searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1)} Results
            </li>
            <>
              <List searchTerm={searchTerm}/>
            </>
          </>
          :
          <>
            <li id={`category_${category.id}`} ref={inputEl} onClick={() => {
              toggleArrow(`category_${category.id}`)
              scrollToView()
              clearSearchTerm()
              selectedCategory(`category_${category.id}`)
            }}>{category.name} {arrow(category.showItems)}</li>
            <>
            {category.showItems ? <List category_id={category.id} searchTerm={searchTerm}/> : "" }
            </>
          </>
        }
        
      </>
      }
    </div>
  )
}


export default Category;
