import React from 'react';
const items = require('../data.js').data;

function Results ({ selectedSection, searchTerm }) {
  var alphaOrder = ["A", "B", "C", "D", "E"];
  var colors = ["#FEBCB5", "#FECFB5", "#FEF1B5", "#B8FEB5", "#D4B5FE"];

  const filteredArr = () => {
    if(searchTerm.length > 2) {
      var results = items.filter(item => (
        item.name.toLowerCase().includes(searchTerm) ||
        item.tags.indexOf(searchTerm) > -1 ||
        (item.id < 19 && item.inline_extra!= null && item.inline_extra.toLowerCase().includes(searchTerm)) ||
        (item.id < 19 && item.description != null && item.description.toLowerCase().includes(searchTerm)) ||
        (item.category_id === 19 && item.description != null && item.description.length > 0 && JSON.stringify(item.description).toLowerCase().includes(searchTerm))
      ))
      return results
    } else {
      return items.filter(i => i.category_id === parseInt(selectedSection))
    }
  }

  const resultsDisplay = () => {
    if(searchTerm.length > 2) return "set-meal-results"
    if(parseInt(selectedSection) === 19) return "set-meal-results"
    else return "results-box";
  }

  return (
    <div data-testid="Results" className={resultsDisplay()}>
    {filteredArr().map((i, index) => {
        if(searchTerm.length > 2 && i.category_id < 19)
        {
          return <div className="card" key={`item${i.id}`}>
                  <div className="row_1">
                    <div className="card-index"></div>
                    <div className="card-name">
                      {i.name}
                      <small><>{i.inline_extra !== null ? <small className="inline">{i.inline_extra}</small> : ""}</></small>
                    </div>
                    <div><span className="price">£{i.price.toFixed(2)}</span></div>
                  </div>
                  <div className="row_2">
                    <p><>{i.description !== null ? <span className="description"><small>{i.description}</small></span>: ""}</></p>
                  </div>
                </div> 
        }

        if(searchTerm.length > 2 && i.category_id === 19)
        {
          return  <div key={`item${i.id}`} className="card card-set-meal" style={{ backgroundColor: colors[`${i.id % 186}`], padding: "0.2em 0.5em", borderRadius: "15px"}}>
                    <div className="row_1">
                      <div className="alpha-index">{alphaOrder[i.id % 186]}</div>
                      <div className="card-name">{i.name}</div>
                      <div><span className="price">£{i.price.toFixed(2)}</span></div>
                    </div>
                    <div className="row_2">
                      <p>
                        <>
                          {i.description.length > 0 ? 
                            <>{i.description.map( (item, index) => {
                                return <p key={`set-meal-item_${index}`} className="set-menu-item">{item.name} <span>{item.inline_extra}</span></p>
                              })}
                            </>
                            : 
                            ""
                          }
                        </>
                      </p>
                    </div>
                  </div>
        }

        if(parseInt(selectedSection) === 19 && i.category_id === parseInt(selectedSection)) {
          return  <div key={`item${i.id}`} className="card card-set-meal" style={{ backgroundColor: colors[index], padding: "0.2em 0.5em", borderRadius: "15px"}}>
                    <div className="row_1">
                      <div className="alpha-index">{alphaOrder[index]}</div>
                      <div className="card-name">{i.name}</div>
                      <div>
                        <span className="price">£{i.price.toFixed(2)}</span>
                      </div>
                    </div>
                    <div className="row_2">
                      <p>
                        <>
                          {i.description.length > 0 ? 
                            <>{i.description.map( (item, index) => {
                                return <p key={`set-meal-item_${index}`} className="set-menu-item">{item.name} <span>{item.inline_extra}</span></p>
                              })}
                            </>
                            : 
                            ""
                          }
                        </>
                      </p>
                    </div>
                  </div>
        }

        if(i.category_id === parseInt(selectedSection)) {
          return <div className="card" key={`item${i.id}`}>
                  <div className="row_1">
                    <div className="card-index">{index + 1}</div>
                    <div className="card-name">
                      {i.name}
                      <small><>{i.inline_extra !== null ? <small className="inline">{i.inline_extra}</small> : ""}</></small>
                    </div>
                    <div>
                      <span className="price">£{i.price.toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="row_2">
                    <p><>{i.description !== null ? <span className="description"><small>{i.description}</small></span>: ""}</></p>
                  </div>
                </div> 
        }

        
        return "Nothing to see here"
    })}
    {filteredArr().length === 0 ?
    <>
      <div className="card">
        <div className="row_1">
          <div className="card-name">
            There were no {searchTerm} results
          </div>
        </div>
      </div> 
    </>
    :
    ""
    }
    </div>
  )
}

export default Results;
