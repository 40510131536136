import React from 'react';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Category from './Category';



function CategoryNav ({ selectedCategory, clearSearchTerm, searchTerm }) {
  const isDesktop = useMediaQuery({ query: '(min-width: 800px)' })
  const [categories, setCategories] = useState([{id: "1", name: "Starters", showItems: false}, 
                                                {id: "2", name: "Salt, Chilli & Pepper Dishes", showItems: false}, 
                                                {id: "3", name: "Chef's Specialities", showItems: false},
                                                {id: "4", name: "Black Bean Sauce", showItems: false}, 
                                                {id: "5", name: "Satay Sauce Dishes", showItems: false}, 
                                                {id: "6", name: "Chop Suey Dishes", showItems: false}, 
                                                {id: "7", name: "Chicken Dishes", showItems: false}, 
                                                {id: "8", name: "Beef Dishes", showItems: false}, 
                                                {id: "9", name: "Pork Dishes", showItems: false}, 
                                                {id: "10", name: "King Prawn Dishes", showItems: false}, 
                                                {id: "11", name: "Duck Dishes", showItems: false}, 
                                                {id: "12", name: "Curry Dishes", showItems: false}, 
                                                {id: "13", name: "Chow Mein Dishes", showItems: false}, 
                                                {id: "14", name: "Sweet & Sour Dishes", showItems: false}, 
                                                {id: "15", name: "Rice Dishes", showItems: false}, 
                                                {id: "16", name: "Foo Young Dishes", showItems: false}, 
                                                {id: "17", name: "English Dishes", showItems: false}, 
                                                {id: "18", name: "Side Dishes", showItems: false}, 
                                                {id: "19", name: "Set Meals", showItems: false}])

  const toggleArrow = (e) => {
    let clickedOn = e.split('_')[1];
    let newArr = [...categories];
    let prevCategoryId;

    // Close category section if there are more than one section open
    if(newArr.filter(obj => obj.showItems === true ).length > 0) {
      newArr.find(obj => { if(obj.showItems === true) { 
        obj.showItems = !obj.showItems
        prevCategoryId = obj.id
      }})
      setCategories(newArr)
    }

    if(prevCategoryId !== clickedOn) {
      newArr.find(obj => { if(obj.id === clickedOn) obj.showItems = !obj.showItems })
      setCategories(newArr)
    }
  }

  return (
    <div data-testid="CategoryNav" className="category-nav">
      <ul>
        {searchTerm.length > 2 && !isDesktop ?
        <>
          <Category selectedCategory={selectedCategory} clearSearchTerm={clearSearchTerm} searchTerm={searchTerm}/>
        </>
        :
        <>
          {categories.map(c => (<Category key={c.id} category={c} toggleArrow={toggleArrow} selectedCategory={selectedCategory} clearSearchTerm={clearSearchTerm} searchTerm={searchTerm}/>))}
        </>
        }
      </ul> 
    </div>
  )
};

export default CategoryNav;
