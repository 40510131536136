import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import Header from './components/Header'
import Notification from './components/Notifications'
import CategoryNav from './components/CategoryNav'
import Results from './components/Results'
import Map from './components/Map'


function App() {
  const isDesktop = useMediaQuery({ query: '(min-width: 800px)' })
  const [selectedSection, setSelectedSection] = useState("1");
  const [searchTerm, setSearchTerm] = useState("");
  const selectedCategory = (data) => {
    var selected = data.split('_')[1];
    setSelectedSection(selected);
  }

  const handleSearch = (input) => {
    var currentSearchTerm = input.target.value.toLowerCase();
    setSearchTerm(currentSearchTerm);
    // TODO sanitise input!
  } 

  const clearSearchTerm = () => {
    // Clear search term
    setSearchTerm("");
  }

  // const firebaseApp = firebase.apps[0];
  
  return (
    <div>
      <div>
      </div>
      <Header handleSearch={handleSearch} searchTerm={searchTerm}/>
      <Notification selectedSection={selectedSection}/>
      <div className="container">
        <CategoryNav selectedCategory={selectedCategory} clearSearchTerm={clearSearchTerm} searchTerm={searchTerm}/>
        {isDesktop ? <Results selectedSection={selectedSection} searchTerm={searchTerm}/> : ""}
        <Map/>
      </div>
    </div>
  );
}

export default App;
