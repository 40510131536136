import React from 'react';
const items = require('../data.js').data;

function List ({ category_id, searchTerm }) {
  var alphaOrder = ["A", "B", "C", "D", "E"];
  var colors = ["#FEBCB5", "#FECFB5", "#FEF1B5", "#B8FEB5", "#D4B5FE"];

  const filteredArr = () => {
    if(searchTerm.length > 2) {
      var results = items.filter(item => (
        item.name.toLowerCase().includes(searchTerm) ||
        item.tags.indexOf(searchTerm) > -1 ||
        (item.id < 19 && item.inline_extra!= null && item.inline_extra.toLowerCase().includes(searchTerm)) ||
        (item.id < 19 && item.description != null && item.description.toLowerCase().includes(searchTerm)) ||
        (item.category_id === 19 && item.description != null && item.description.length > 0 && JSON.stringify(item.description).toLowerCase().includes(searchTerm))
      ))
      return results
    } else {
      return items.filter(i => i.category_id === parseInt(category_id))
    }
  }

  return (
    <div>
      {parseInt(category_id) === 12 ? 
        <div className="rice-notif">
          <p>Including Boiled Rice or Chips - Fried Rice Extra <b>+ £0.10</b></p>
          <p>Add Mushrooms <b>+ £0.50</b> and changes to a <b>large container</b></p>
          <p>Without Rice or Chips <b>- £0.20</b> off</p>
        </div>
        :
        ""
      }
      {parseInt(category_id) === 17 ? 
        <div className="rice-notif">
          <p>Including Chips and Peas</p>
        </div>
        :
        ""
      }
      {items.length > 0 ?
      <>
        {filteredArr().map((i, index) => {
          if(searchTerm.length > 2 && i.category_id < 19)
          {
            return <div className="menu-item" key={`item${i.id}`}>
                    <div className="menu-item-name">
                      <p className="index">{index + 1}.</p>
                      <p>
                        {i.name}
                        <>{i.inline_extra !== null ? <small className="inline">{i.inline_extra}</small> : ""}</>
                        <br/><>{i.description !== null ? <span className="description"><small>{i.description}</small></span>: ""}</>
                      </p>
                    </div>

                    <p className="price">£{i.price.toFixed(2)}</p>
                  </div> 
          }

          if(searchTerm.length > 2 && i.category_id === 19)
          {
            return  <div className="menu-item" key={`item${i.id}`} style={{ backgroundColor: colors[i.id % 186], padding: "0.2em 0.5em", margin: "0.5em 0", borderRadius: "15px"}}>
                      <div className="menu-item-name">
                        <h3 className="alpha-index">{alphaOrder[i.id % 186]}</h3>
                        <div>
                          <h3>{i.name}</h3>
                          <>
                            {i.description.length > 0 ? 
                              <>{i.description.map( (item, index) => {
                                return <p key={`set-meal-item_${index}`} className="set-menu-item">{item.name} <span>{item.inline_extra}</span></p>
                                })}
                              </>
                              : 
                              ""
                            }
                          </>
                        </div>
                      </div>
                      <h3 style={{ margin: "0.2em 0.5em" }}>£{i.price.toFixed(2)}</h3>
                    </div> 
          }
          
          if(parseInt(category_id) === 19 && i.category_id === parseInt(category_id)) {
            // Set menu items
            return <div className="menu-item" key={`item${i.id}`} style={{ backgroundColor: colors[index], padding: "0.2em 0.5em", margin: "0.5em 0", borderRadius: "15px"}}>
                    <div className="menu-item-name">
                      <h3 className="alpha-index">{alphaOrder[index]}</h3>
                      <div>
                        <h3>{i.name}</h3>
                        <>
                          {i.description.length > 0 ? 
                            <>{i.description.map( (item, index) => {
                              return <p key={`set-meal-item_${index}`} className="set-menu-item">{item.name} <span>{item.inline_extra}</span></p>
                              })}
                            </>
                            : 
                            ""
                          }
                        </>
                      </div>
                    </div>
                    <h3 style={{ margin: "0.2em 0.5em" }}>£{i.price.toFixed(2)}</h3>
                  </div> 
          }

          if(i.category_id === parseInt(category_id)) {
            return <div className="menu-item" key={`item${i.id}`}>
                    <div className="menu-item-name">
                      <p className="index">{index + 1}.</p>
                      <p>
                        {i.name}
                        <>{i.inline_extra !== null ? <small className="inline">{i.inline_extra}</small> : ""}</>
                        <br/><>{i.description !== null ? <span className="description"><small>{i.description}</small></span>: ""}</>
                      </p>
                    </div>

                    <p className="price">£{i.price.toFixed(2)}</p>
                  </div> 
          }
          return ""
        })}
      </>
      :
      "There are currently no items"
      }
      {filteredArr().length === 0 ?
      "There were no " + searchTerm + " results"
      :
      ""
      }
    </div>
  )
}

export default List;
