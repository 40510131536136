import React from 'react';
import { useMediaQuery } from 'react-responsive';

function Notifications ({ selectedSection }) {
  const isDesktop = useMediaQuery({ query: '(min-width: 700px)' })

  return (
    <div className="notification-container">
      <div className="notif notif1">We welcome telephone collection and walk-ins</div>
      <div className="notif notif2">Prices from May 2022 Menu</div>
      <div className="notif notif3">All prices are inclusive of V.A.T</div>
      <div className="notif notif4">Rice or Chips excluded unless otherwise stated</div>
      <div className="notif notif5">
        Food Allergies and Intolerance
        <p><small>Some of our dishes may contain these 14 allergens:</small></p>
        <div className="image-container">
          {isDesktop ? 
          <img src={process.env.PUBLIC_URL + '/img/allergens_1.png'} alt="Allergen information" style={{width: "100%"}}/>
          :
          <img src={process.env.PUBLIC_URL + '/img/allergens_2.png'} alt="Allergen information" style={{width: "100%"}}/>
          }
        </div>
        <p><small>which some people may have a reaction to. Please inform our staff before ordering if you suffer from any food allergies. We will do our best to advise you.</small></p>
      </div>
      {isDesktop && selectedSection == 12 ? 
        <div className="rice-notif">
          <p>Including Boiled Rice or Chips - Fried Rice Extra <b>+ £0.10</b></p>
          <p>Add Mushrooms <b>+ £0.50</b> and changes to a <b>large container</b></p>
          <p>Without Rice or Chips <b>- £0.20</b> off</p>
        </div>
      :
        ""
      }
      {isDesktop && selectedSection == 17 ? 
        <div className="rice-notif">
          <p>Including Chips and Peas</p>
        </div>
      :
        ""
      }
    </div>
  )
}

export default Notifications;
