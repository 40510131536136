import React from 'react';
import SearchBox from './SearchBox'

function Header ({ handleSearch, searchTerm }) {
  return (
    <header>
    <nav className="banner">
        <div className="title-box">
            <h1 className="title">Happy Garden</h1>
            <div className="underline"></div>
            <h2>Castle Bromwich</h2>
            <a href="tel:01217481739" className="remove-anchor-style">
                <div className="telephone"><h3>0121 748 1739</h3></div>
            </a>
        </div>
        <SearchBox handleSearch={handleSearch} searchTerm={searchTerm}/>
    </nav>
  </header>
  )
}

export default Header;
