import React from 'react';


function SearchBox ({ handleSearch, searchTerm }) {
  return (
    <div data-testid="SearchBox"className="search-box">
      <input className="search-input" type="text" name="search" placeholder="Search something here" value={searchTerm} onChange={handleSearch}/>
      <button className="search-btn" type="submit"><i className="fa fa-search"></i></button>
    </div>
  )
}



export default SearchBox;
